<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <!-- <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            src="@/assets/images/pages/stats-cuate.svg"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <div class="text-center">
            <b-img
              fluid
              width="60"
              src="@/assets/images/logo/logo.png"
              alt="Login V2"
            />
            <b-card-title title-tag="h2" class="font-weight-bold mb-1">
              Office Arch
            </b-card-title>
          </div>
          <b-card-text class="mb-2">
            Por favor, faça login para usar o sistema
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="email@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Senha</label>
                  <b-link :to="{ name: 'auth-forgot-password-v2' }">
                    <small>Esqueceu a senha?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                class="mt-3"
                block
                @click="checkUser"
              >
                Login
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-5">
            <span>V.2.0.0 </span>
            <!-- <b-link :to="{ name: 'page-auth-register-v2' }">
              <span>&nbsp;Criar conta</span>
            </b-link> -->
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
    <b-modal
      v-model="modalWorkspace"
      hide-footer
      hide-header-close
      no-close-on-backdrop
      modal-class="modal-primary"
      centered
      title="Escolha áreas de trabalho"
    >
      <b-card-text>
        Existem diversas áreas de trabalho vinculadas ao seu usuário,
        <strong>selecione a desejada</strong>:
      </b-card-text>
      <v-select
        id="custon-select-local"
        @search="
          (search, loading) => {
            onFiltered(search);
          }
        "
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        placeholder="Selecione"
        v-model="workspaceSelected"
        :options="workspaces"
        :getOptionLabel="(item) => item.workspaces.workspace_title"
      />
      <b-row class="mt-2">
        <b-col class="justify-content-between d-flex">
          <b-button @click="closeModal" variant="warning">Cancelar</b-button>
          <b-button
            :disabled="!workspaceSelected"
            @click="setWorkspace"
            variant="primary"
            >Selecionar</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BModal,
  // BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BModal,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    // BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      modalWorkspace: false,
      userEmail: '',
      workspaceSelected: null,
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    multipleWorkspaces() {
      return this.$store.getters.multipleWorkspaces;
    },
    workspaces() {
      return this.$store.getters.workspaces;
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/stats-cuate.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    setWorkspace() {
      this.$store.commit(
        'SET_CURRENT_WORKSPACE',
        this.workspaceSelected.workspaces
      );
      this.closeModal();
      this.$router.push({ name: 'home' });
    },
    closeModal() {
      this.modalWorkspace = false;
    },
    checkUser() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          let data = { email: this.userEmail, password: this.password };
          this.$store
            .dispatch('login', data)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Login Realizado com sucesso',
                  icon: 'SuccessIcon',
                  variant: 'success',
                },
              });
              if (this.multipleWorkspaces) {
                this.modalWorkspace = true;
              } else {
                this.$router.push({ name: 'home' });
              }
            })
            .catch(() => {
              // console.log('err', err)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Email ou senha incorretos',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
